import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { H2, Slide } from '@cenk1cenk2-presentations/react-reveal-base';
import Logo from '@coding-school/presentations-web-development-common/assets/images/cs.png';
import Babeg from '@coding-school/presentations-web-development-common/assets/images/sponsors/babeg.jpg';
import Beko from '@coding-school/presentations-web-development-common/assets/images/sponsors/beko-logo.jpg';
import Build from '@coding-school/presentations-web-development-common/assets/images/sponsors/BUILD_logo_4c.jpg';
import Diamir from '@coding-school/presentations-web-development-common/assets/images/sponsors/diamirholding.png';
import FHKaerenten from '@coding-school/presentations-web-development-common/assets/images/sponsors/fh-kaernten.png';
import Humonomed from '@coding-school/presentations-web-development-common/assets/images/sponsors/humanomed.png';
import Industriellenvereinigung from '@coding-school/presentations-web-development-common/assets/images/sponsors/Industriellenvereinigung.png';
import Bankenverband from '@coding-school/presentations-web-development-common/assets/images/sponsors/logo-bankenverband.png';
import Raiffeisen from '@coding-school/presentations-web-development-common/assets/images/sponsors/Raiffeisen_Landesbank_Kaernten.png';
import { Fragment } from 'react';
export const EndSlide = (props) => {
    const sponsors = [Babeg, FHKaerenten, Raiffeisen, Bankenverband, Industriellenvereinigung, Build, Diamir, Humonomed, Beko];
    return (_jsx(Fragment, { children: _jsx(Slide, { children: _jsxs("div", Object.assign({ className: "grid grid-cols-2" }, { children: [_jsx("div", Object.assign({ className: "self-center grid grid-cols-3 gap-6 m-8" }, { children: sponsors.map((s) => (_jsx("div", Object.assign({ className: "bg-white border-4 border-gray-600 shadow-2xl grid-span-1 w-64 h-64 drop-shadow-2xl flex" }, { children: _jsx("img", { src: s, className: "place-self-center m-2 items-stretch" }, void 0) }), s))) }), void 0), _jsxs("div", Object.assign({ className: "self-center" }, { children: [_jsx("img", { src: Logo, className: "place-self-center w-64 h-64" }, void 0), _jsx(H2, { children: "Vielen Dank f\u00FCr Ihre Aufmerksamkeit." }, void 0), props.children] }), void 0)] }), void 0) }, void 0) }, void 0));
};
