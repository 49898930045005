import { H3, Li, Note, Slide, Ul, Fragment as F } from '@cenk1cenk2-presentations/react-reveal-base'
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'

const Page: React.FC = () => {
  return (
    <Fragment>
      <Slide>
        <H3 className="mx-auto self-center grid-span-2">
          <FontAwesomeIcon icon={faClipboardCheck} className="mr-4" />
          Ground rules
        </H3>
        <Ul className="max-w-6xl mx-auto list-none">
          <Li fragment={true}>Please, do not hesitate to contact me.</Li>
          <Li fragment={true}>Feel free to stop me out.</Li>
          <Li fragment={true}>Interract more.</Li>
        </Ul>

        <Note data-markdown>{notes}</Note>
      </Slide>
    </Fragment>
  )
}

// ~3m
const notes = `
Okay, now that is out of the way. Let us get down to business and lay the ground rules for the upcoming course.
I do not know whether it is the wisest thing to do and call it rules but they are mostly humble suggestions of mine for you.

With everything the coding-school related like the absence from the classes already been stressed enough before me by my colleagues, I just want to go over some simple ground rules to communicate with each other better.

**{NEXT}** First and foremost, please do not hesitate to contact me whenever you feel like it. Whether you might have question marks in your mind right before you go to bed in the night or did not properly fit something into your mind while having some dinner in the evening, just write to me through slack and I will try to reply to it as soon as I see it.

**{NEXT}** If something is not clear as I have imagined it to be while preparing for this course, please feel free to interrupt me and ask anything that you require to make it more clear. Because the most important thing is not me mumbling about programming here and giving you some examples to do, the most important thing is you to understand the basic concepts and the idea behind them. This also applies to cases where a water-down the concepts, you can always give me feedback to speed things up if anything feels dummed down for each and everyone of you, since I do not want things to get boring neither.

**{NEXT}** Again, something similar to one before but vastly different. If you can interact with the course more, it will help with the longevity of the information. What I do mean by that is while we are going through a concept, after the initial part of it is finished, and you have not found what you are looking for in that concept, we can go a bit off-script and go over that too. Any additional information for the class would be beneficial to all of us. Of course, there will be times the thing you might ask will be the next thing coming up, but that's not a hassle for me to tell that. So this way I do believe that everything will be more enjoyable for both our accounts.

`

export default Page
