import { jsx as _jsx } from "react/jsx-runtime";
import { useReveal } from '@cenk1cenk2-presentations/react-reveal-base';
import overlay from '@coding-school/presentations-web-development-common/assets/images/bg-overlay.png';
import consola from 'consola';
import { Fragment, useEffect, useState } from 'react';
import { createGlobalStyle, css } from 'styled-components';
import { isMiddleSlide } from '../utils/is-middle-slide';
export const CodingSchoolOverlay = () => {
    const { reveal } = useReveal();
    const [activeOverlay, setActiveOverlay] = useState(false);
    useEffect(() => {
        if (!reveal) {
            consola.warn('Reveal.js is not ready yet.');
            return;
        }
        setActiveOverlay(isMiddleSlide(reveal));
        reveal.on('slidechanged', () => {
            setActiveOverlay(isMiddleSlide(reveal));
        });
    }, [reveal]);
    return _jsx(Fragment, { children: activeOverlay && _jsx(CodingSchoolOverlayStyle, {}, void 0) }, void 0);
};
export const CodingSchoolOverlayStyle = createGlobalStyle(() => css `
    .reveal.slide {
      background: url(${overlay}) no-repeat;
      background-position: right -5% bottom 50%;
    }
  `);
