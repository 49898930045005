import { H3, Note, Slide } from '@cenk1cenk2-presentations/react-reveal-base'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'

const Page: React.FC = () => {
  return (
    <Fragment>
      <Slide>
        <H3 className="mx-auto self-center grid-span-2">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-4" />
          Disclosure
        </H3>
        <Note data-markdown>{notes}</Note>
      </Slide>
    </Fragment>
  )
}

// ~40s
const notes = `
Before jumping to anything else, I would like to disclose that I have prepared this course fully in English.

I am unfortunately not the best non-native german speaker around. Therefore we thought it would be both in our best interest to do the course in English where I can explain everything much clearer.

I hope that this would cause no problems between us and just wanted to get this out of the way before pressing further.
`

export default Page
