import { H3, Li, Note, Slide, Ul, Fragment as F } from '@cenk1cenk2-presentations/react-reveal-base'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'

const Page: React.FC = () => {
  return (
    <Fragment>
      <Slide>
        <H3 className="mx-auto self-center grid-span-2">
          <FontAwesomeIcon icon={faFile} className="mr-4" />
          Course Material
        </H3>
        <Ul className="max-w-6xl mx-auto list-none">
          <Li fragment={true}>Will be available through academy.coding.jetzt</Li>
          <Li fragment={true}>Slides will be accesible online as well</Li>
          <Li fragment={true}>Will include the transcript</Li>
        </Ul>

        <Note data-markdown>{notes}</Note>
      </Slide>
    </Fragment>
  )
}

// ~3m
const notes = `
As always the course material will be available through the academy page.

These slides are hosted on a web server so the link for each lesson and the PDF form will be also available at the end of each lecture.

Both the web-hosted form and the PDF will include the transcript. For accessing the speaker notes on the web-hosted version please press the S key.

And yes, I have already pre-written everything down, so that when or if you want to go over something on your own, everything should be available and accessible to you.

`

export default Page
