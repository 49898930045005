import { H3, Li, Note, Slide, Ul } from '@cenk1cenk2-presentations/react-reveal-base'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'

const Page: React.FC = () => {
  return (
    <Fragment>
      <Slide>
        <H3 className="mx-auto self-center grid-span-2">
          <FontAwesomeIcon icon={faUser} className="mr-4" />
          Who am I?
        </H3>
        <Ul className="max-w-6xl mx-auto list-none">
          <Li fragment={true}>
            I am Cenk. <small className="text-gray-500">ck@webundsoehne.com</small>
          </Li>
          <Li fragment={true}>Focused in backend and developer operations.</Li>
          <Li fragment={true}>Working mainly with TypeScript these days.</Li>
        </Ul>

        <Note data-markdown>{notes}</Note>
      </Slide>
    </Fragment>
  )
}

// ~1m
const notes = `
So let us start with the irrelevant stuff first, and get to know each other a bit.

I will go first and introduce myself if nobody has any objection to that.

**{NEXT}** I am Cenk Kilic, you can reach me through slack with the same name. Or the through email that is disclosed in the slide.

**{NEXT}** I am mostly focused on backend and developer operations on web-based applications.
Additional to that and mostly casually, I would like to do frontend and system architecture design in my free-time.

**{NEXT}** I have been going through a fair share of programming languages and frameworks and always like to experiment with the things that I have not tried in programming yet but since 2017, I have been working with mainly Typescript.
`

export default Page
