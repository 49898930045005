import { Presentation } from '@cenk1cenk2-presentations/react-reveal'
import { CodingSchoolOverlay, Footer } from '@coding-school/presentations-web-development-common'
import React, { Fragment } from 'react'

import * as Slides from './slides'

const App: React.FC = () => {
  return (
    <Fragment>
      <Presentation overlay="firstAndLast">
        <CodingSchoolOverlay />
        <Footer />

        <Slides.Greetings />

        <Slides.Disclosure />
        <Slides.WhoAmI />
        <Slides.MoreAboutYou />
        <Slides.GroundRules />
        <Slides.CourseMaterial />

        <Slides.Goodbye />
      </Presentation>
    </Fragment>
  )
}

export default App
