import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useReveal } from '@cenk1cenk2-presentations/react-reveal-base';
import Logo from '@coding-school/presentations-web-development-common/assets/images/logo-cs-light.png';
import consola from 'consola';
import { Fragment, useEffect, useState } from 'react';
import { isMiddleSlide } from '../utils/is-middle-slide';
export const Footer = () => {
    const { reveal } = useReveal();
    const [showFooter, setShowFooter] = useState(false);
    useEffect(() => {
        if (!reveal) {
            consola.warn('Reveal.js is not ready yet.');
            return;
        }
        setShowFooter(isMiddleSlide(reveal));
        reveal.on('slidechanged', () => {
            setShowFooter(isMiddleSlide(reveal));
        });
    }, [reveal]);
    return (_jsx(Fragment, { children: showFooter && (_jsxs("div", Object.assign({ className: "absolute bottom-0 left-0 text-lg pb-2 pl-1 flex items-center" }, { children: [_jsx("div", Object.assign({ className: "border-4 rounded-xl shadow-lg mr-2 border-gray-200 dark:border-gray-800" }, { children: _jsx("img", { src: Logo, className: "h-20 mx-4" }, void 0) }), void 0), _jsxs("div", Object.assign({ className: "flex flex-col text-sm" }, { children: [_jsx("div", Object.assign({ className: "font-semibold" }, { children: "Web Development" }), void 0), _jsx("div", Object.assign({ className: "text-gray-400 dark:text-gray-600" }, { children: "Grundlagen der Programmierung & JavaScript" }), void 0)] }), void 0)] }), void 0)) }, void 0));
};
