import { H3, Li, Note, Slide, Ul, Fragment as F } from '@cenk1cenk2-presentations/react-reveal-base'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'

const Page: React.FC = () => {
  return (
    <Fragment>
      <Slide>
        <H3 className="mx-auto self-center grid-span-2">
          <FontAwesomeIcon icon={faQuestionCircle} className="mr-4" />
          and a bit more about you...
        </H3>
        <Ul className="max-w-6xl mx-auto list-none">
          <Li fragment={true}>How tech-savy you are?</Li>
          <Li fragment={true}>Have you got any prior programming experience?</Li>
          <Li fragment={true}>How much do you know about and your interest level about blockchain?</Li>
        </Ul>

        <Note data-markdown>{notes}</Note>
      </Slide>
    </Fragment>
  )
}

// ~1m+~1m
const notes = `
To custom-tailor the experience to all of your tastes, I also would like to know a bit more about you.

If you are not that shy and on the contrary chattery like me, we can have a little friendly chat before we get down to the business, I kindly ask you to please go ahead and introduce yourselves. But I will be very understanding if you want to have no part in this part as well, so you can stay silent throughout this as well.

**{NEXT}** I would like to especially know how tech-savvy you are, what are your interests in the area of computing
**{NEXT}** and whether you got any prior programming experience or not.

I sincerly hope that somebody will take the initiative, so we can get this going with a push. Any volunteers?

**{AFTER_TALKS}** And on a side-note for what I have planned to be the ongoing example for this course, I thought that creating a blockchain-like structure where we can apply almost everything that we will learn throughout the course will provide us a practical end-result, which is of-course practical in quotes. I do personally very much like to have an end result while doing programming, so that's where the reasoning is coming from.

**{NEXT}** So more on the project itself while we go through we the classes, but again if you are willing I just want to know a bit more about what do you think about blockchain and this idea of ongoing project of ours.

Like:
  - Do you have any interest in cryptocurrencies or blockchain itself?
  - Or do you not care about it at all.
  - Which is both fine, of course.
`

export default Page
