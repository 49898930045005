import { Note, Slide } from '@cenk1cenk2-presentations/react-reveal-base'
import { EndSlide } from '@coding-school/presentations-web-development-common'
import { Fragment } from 'react'

const Page: React.FC = () => {
  return (
    <Fragment>
      <EndSlide>
        <Note data-markdown>{notes}</Note>
      </EndSlide>
    </Fragment>
  )
}

const notes = `
Thats all on this preliminary introduction class.

Thanks for listening to me.
`

export default Page
