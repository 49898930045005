import { H2, Note, Slide } from '@cenk1cenk2-presentations/react-reveal-base'
import { StartSlide } from '@coding-school/presentations-web-development-common'
import { faJsSquare } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment, useState } from 'react'

const Page: React.FC = () => {
  return (
    <Fragment>
      <StartSlide certificate="Web Development">
        <H2>
          Grundlagen der Programmierung <br />&<br /> <FontAwesomeIcon icon={faJsSquare} /> JavaScript
        </H2>
        <Note data-markdown>{notes}</Note>
      </StartSlide>
    </Fragment>
  )
}

// ~30s
const notes = `
Hello everyone,

I would like to welcome you to the next step of our curriculum, where we will learn more about the thing that gave life to modern and interactive web as we know it.

Whatever programming language you choose for the backend, in frontend it always comes back to one thing which is javascript, even though some unsuccessful attempts were done to dethrone it, its still the king!
`

export default Page
